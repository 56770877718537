import axios from 'axios';

const Dashboard = () => {
  async function api() {
    const url = 'https://staging-epicenter.epicodus.com/courses/1119/code_reviews/7201';
    const response = await axios.get(url, { withCredentials: true});
    console.log(response.data);
  }

  return (
    <main style={{ padding: '1rem 0' }}>
      <h2>Dashboard</h2>
      <button onClick={api}>poke the API</button>
    </main>
  );
};

export default Dashboard;