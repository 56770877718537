import axios from 'axios';

const Home = () => {
  async function checkLoginStatus() {
    const response = await axios.get('https://staging-epicenter.epicodus.com/logged_in', { withCredentials: true});
    console.log(response.data);
  }

  return (
    <main style={{ padding: '1rem 0' }}>
      <h2>Home</h2>
      <button onClick={checkLoginStatus}>logged in?</button>
    </main>
  );
};

export default Home;